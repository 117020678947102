// dependencies
import React from 'react';


// media
import img1 from '@media/gallery/1.jpg';
import img2 from '@media/gallery/2.jpg';
import img3 from '@media/gallery/3.jpg';
import img4 from '@media/gallery/4.jpg';
import img5 from '@media/gallery/5.jpg';
import img6 from '@media/gallery/6.jpg';
import img7 from '@media/gallery/7.jpg';
import img9 from '@media/gallery/9.jpg';
import img10 from '@media/gallery/10.jpg';
import img12 from '@media/gallery/12.jpg';
import img13 from '@media/gallery/13.jpg';
import img14 from '@media/gallery/14.jpg';
import img15 from '@media/gallery/15.jpg';
import img17 from '@media/gallery/17.jpg';
import img18 from '@media/gallery/18.jpg';
import img19 from '@media/gallery/19.jpg';
import img20 from '@media/gallery/20.jpg';


function Gallery() {
    return (
        <div className='page'>
            <div className='content'>
                <div className='.banner'><br/><br/><br/><br/></div>
                <h1>Take A Look At Some Of Our Consulting Work!</h1>
                <div className='image-gallery'>
                    <div className='gallery-item'>
                        <img src={img1} alt='Living Room Design, Remodel & Staging' draggable='false'/>
                        <p className='description'>Living Room Design, Remodel & Staging</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img2} alt='Norwall Carrara Marble Kitchen Countertops' draggable='false'/>
                        <p className='description'>Norwall Carrara Marble Kitchen Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img3} alt='Caesarstone Quartz Master Bathroom Countertops' draggable='false'/>
                        <p className='description'>Caesarstone Quartz Master Bathroom Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img4} alt='Caesarstone Quartz Kitchen Countertops' draggable='false'/>
                        <p className='description'>Caesarstone Quartz Kitchen Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img5} alt='Grey Porcelain Floor Tile' draggable='false'/>
                        <p className='description'>Grey Porcelain Floor Tile</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img6} alt='Caesarstone Quartz Bathroom Countertop' draggable='false'/>
                        <p className='description'>Caesarstone Quartz Bathroom Countertop</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img7} alt='Caesarstone Quartz Master Bathroom Countertops' draggable='false'/>
                        <p className='description'>Caesarstone Quartz Master Bathroom Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img9} alt='Full Bathroom & Shower Design & Remodel With Prisma Gris Ceramic Tile & Crest Quartz Countertops' draggable='false'/>
                        <p className='description'>Full Bathroom Design With Prisma Gris Ceramic Tile & Crest Quartz Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img10} alt='Montauk Quartz Kitchen and Waterfall Island Countertops' draggable='false'/>
                        <p className='description'>Montauk Quartz Kitchen and Waterfall Island Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img12} alt='White Galaxy Quartz Kitchen and Waterfall Island Countertops' draggable='false'/>
                        <p className='description'>White Galaxy Quartz Kitchen and Waterfall Island Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img13} alt='White Splash Leveled Kitchen Countertops' draggable='false'/>
                        <p className='description'>White Splash Leveled Kitchen Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img14} alt='Concrete Grey Kitchen Countertops' draggable='false'/>
                        <p className='description'>Concrete Grey Kitchen Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img15} alt='Jack And Jill Design & Remodel With Crest Quartz Countertops' draggable='false'/>
                        <p className='description'>Jack And Jill Design & Remodel With Crest Quartz Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img17} alt='Master Bathroom Remodel Design & Norwall Carrara Marble Master Bathroom Countertops' draggable='false'/>
                        <p className='description'>Master Bathroom Remodel Design & Norwall Carrara Marble Master Bathroom Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img18} alt='Master Bath Concept With Norwall Carrara Marble Countertops' draggable='false'/>
                        <p className='description'>Master Bath Concept With Norwall Carrara Marble Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img19} alt='Laundry Room Crest Quartz Countertops' draggable='false'/>
                        <p className='description'>Laundry Room Crest Quartz Countertops</p>
                    </div>
                    <div className='gallery-item'>
                        <img src={img20} alt='Pet Washing Station Design' draggable='false'/>
                        <p className='description'>Pet Washing Station Design</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Gallery;