// dependancies
import React, { useEffect } from 'react';


// css
import '@css/stylesheet.css';


// media
import banner_video from '@media/index/house-vid.mp4';
import our_story from '@media/index/our_story.jpg';
import our_mission from '@media/index/our_mission.jpg';
import residential_development from '@media/index/residential_development.jpg';
import commercial_development from '@media/index/commercial_development.jpg';


function Home() {
    useEffect(() => {
        const vid = document.getElementById('banner_video');
        if (vid) {
            vid.setAttribute('playsInline', '');
        }
    }, []);

    return (
        <div className='content'>
            <div>
                <video id='banner_video' autoPlay loop muted playsInline><source src={banner_video} type="video/mp4"/></video>
            </div>
            <div className='about-us'>
                <h2>About Us</h2>
                <div className='about'>
                    <div className='text-container'>
                        <img className='image' src={our_story} alt='Our Story' draggable='false'/>
                        <h3>Our Story</h3>
                        <p className='text'>
                            We are two brothers and a childhood friend
                            that founded our company with the 
                            initial goal of assisting our parents in
                            obtaining more affordable pricing and improving
                            the cost-efficiency of their home construction. 
                            During their projects, we observed excessive 
                            costs and subpar execution. By stepping in, we 
                            were able to not only reduce expenses but also 
                            resolve these issues. Our growing expertise led 
                            us to realize the potential to extend our 
                            services to others, offering similar benefits 
                            and solutions.
                        </p>
                    </div>
                    <div className='text-container'>
                        <img className='image' src={our_mission} alt='Our Mission' draggable='false'/>
                        <h3>Our Mission</h3>
                        <p className='text'>
                            Our philosophy is grounded in the belief that 
                            success is achieved through assisting others 
                            in reaching their goals. This principle guides 
                            our mission of providing our clients with the 
                            highest quality products and services at the 
                            most competitive prices. We are committed to 
                            supporting you in achieving your aspirations, 
                            dedicating our efforts to facilitate your 
                            journey to success.
                        </p>
                    </div>
                </div>
            </div>
            <div className='dev-consulting'>
                <h2>Development Consulting</h2>
                <div className='dev-container'>
                    <div className='dev'>
                    <div className='text-container'>
                            <h3>Residential Development</h3>
                            <p className='text'>
                                Our expertise encompasses residential 
                                development, covering a range from single 
                                family homes to extensive multi-family 
                                apartment complexes. We offer comprehensive 
                                assistance for your project, whether it 
                                involves constructing a new home or 
                                renovating an existing property. Our team 
                                is dedicated to supporting your residential 
                                development needs.
                            </p> 
                        </div>
                        <img className='image' src={residential_development} alt='Residential Development' draggable='false'/>
                    </div>
                    <div className='com-dev'>
                        {/* <!-- TODO: update this photo with a real commercial job like KFC --> */}
                        <div className='text-container'>
                            <h3>Commercial Development</h3>
                            <p className='text'>
                                Our team is adept in commercial development, 
                                encompassing projects from compact retail 
                                spaces to expansive office buildings and 
                                restaurants. Whether your endeavor involves 
                                the construction of a new building or the 
                                renovation of an existing structure, we are 
                                equipped to offer our expertise and guidance 
                                to ensure the success of your project.
                            </p> 
                        </div>
                        <img className='image' src={commercial_development} alt='Commercial Development' draggable='false'/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;