// dependencies
import React from 'react';


// global variables
var year = new Date().getFullYear();


function Footer() {
    return (
        <footer className='foot'>
            <p>
                Copyright &copy; {year} Athena Development, LLC. All rights reserved.
            </p>
        </footer>
    );
};


export default Footer;